import React from 'react';
import { Button, Form } from 'react-bootstrap';
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';

import '../views/css/map-drop-point.css'

const ExportExcel = ({ excelData, fileName, name, disabledOption }) => {
    
    const fileType = 'application/vnd.openxml formats-officedocument. spreadsheetml. sheet; charset-UTF-8';
    const fileExtension = '.xlsx';

    const exportToExcel = async() => {
        console.log(excelData)
        const ws = XLSX.utils.json_to_sheet(excelData);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data']};
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array'});
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
    }

    return(
        <>
             <Form>
            <Button 
                variant="info" 
                className="excel-btn" 
                onClick={exportToExcel} 
                disabled={disabledOption !== undefined ? disabledOption : false}
            >
                {name !== undefined ? name : 'Export Excel'}
            </Button>
        </Form>
        </>
    )

}

export default ExportExcel;